/** ***********************************************************************************
 * Color design tokens
 *********************************************************************************** */

:root {
  /**
   * Color palette
   */

  /** Black: #202020 */
  --ap-global--palette-black--100: rgba(32, 32, 32, 1);
  --ap-global--palette-black--65: rgba(32, 32, 32, .65);
  --ap-global--palette-black--60: rgba(32, 32, 32, .60);
  --ap-global--palette-black--36: rgba(32, 32, 32, .36);
  --ap-global--palette-black--27: rgba(32, 32, 32, .27);
  --ap-global--palette-black: var(--ap-global--palette-black--100);

  /** Dark gray: #353535 */
  --ap-global--palette-gray-dark--100: rgba(53, 53, 53, 1);
  --ap-global--palette-gray-dark: var(--ap-global--palette-gray-dark--100);

  /** Off white: #fAf9f7 */
  --ap-global--palette-white--100: rgba(250, 249, 247, 1);
  --ap-global--palette-white--70: rgba(250, 249, 247, .70);
  --ap-global--palette-white--30: rgba(250, 249, 247, .30);
  --ap-global--palette-white--25: rgba(250, 249, 247, .25);
  --ap-global--palette-white: var(--ap-global--palette-white--100);
}
