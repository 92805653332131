/** ***********************************************************************************
 * Apus miscellaneous design tokens
 *********************************************************************************** */

:root {

  /**
   * Borders
   */

  --ap-global--Border--black: 1px solid var(--ap-global--palette-black);
  --ap-global--Border--white: 1px solid var(--ap-global--palette-white);
  --ap-global--Border--white-70: 1px solid var(--ap-global--palette-white--70);


  /**
   * Outlines
   */

  --ap-global--Outline--black: 3px solid var(--ap-global--palette-black);


  /**
   * Durations
   */

  --ap-global--Duration--tn: 100ms;
  --ap-global--Duration--xs: 150ms;
  --ap-global--Duration--lm: 300ms;
  --ap-global--Duration--md: 700ms;
  --ap-global--Duration--lg: 1000ms;
  --ap-global--Duration--xl: 3000ms;


  /**
   * Timing functions
   */

  --ap-global--AnimationTimingFunction--bounce: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  --ap-global--AnimationTimingFunction--easeoutcubic: cubic-bezier(0.33, 1, 0.68, 1);
}
