/** ***********************************************************************************
 * Apus typography design tokens
 *********************************************************************************** */

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

@font-face {
  font-family: "PPNeueMontreal";
  src: url("~/assets/fonts/PPNeueMontreal-Variable.woff2") format("woff2-variations");
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: normal;
}

@font-face {
  font-family: "PPNeueMontreal Book";
  src: url("~/assets/fonts/PPNeueMontreal-Book.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: url("~/assets/fonts/EuclidCircularB-Medium-WebS.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

/* per compatibilità su tutti i browser. Vedi https://rwt.io/typography-tips/getting-bent-current-state-italics-variable-font-support */
/* @font-face {
  font-family: "PPNeueMontreal";
  src: url("../fonts/PPNeueMontreal-ThinItalic.woff2") format("woff2");
  font-weight: 100 200;
  font-style: italic;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url("../fonts/PPNeueMontreal-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url("../fonts/PPNeueMontreal-BookItalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url("../fonts/PPNeueMontreal-MediumItalic.woff2") format("woff2");
  font-weight: 500 600;
  font-style: italic;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url("../fonts/PPNeueMontreal-SemiBolditalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url("../fonts/PPNeueMontreal-BoldItalic.woff2") format("woff2");
  font-weight: 800 900;
  font-style: italic;
} */

:root {
  /**
   * Font families
   */

  --ap-global--FontFamily-default: "PPNeueMontreal", "Roboto", sans-serif;
  --ap-global--FontFamily-book: "PPNeueMontreal Book", "Roboto", sans-serif;


  /**
   * Font weights
   */

  --ap-global--FontWeight-light:      300;
  --ap-global--FontWeight-normal:     400;
  --ap-global--FontWeight-medium:     500;
  --ap-global--FontWeight-bold:       700;
  --ap-global--FontWeight-regular:    var(--ap-global--FontWeight-normal);


  /**
   * Utopic steps
   * @link https://utopia.fyi/type/calculator?c=1080,14,1.2,1920,24,1.2,7,2,1440&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12
   */

  /* Step -2: 9.7222px → 16.6667px */
  --step--2: clamp(0.6076rem, 0.0496rem + 0.8267vi, 1.0417rem);
  /* Step -1: 11.6667px → 20px */
  --step--1: clamp(0.7292rem, 0.0595rem + 0.9921vi, 1.25rem);
  /* Step 0: 14px → 24px */
  --step-0: clamp(0.875rem, 0.0714rem + 1.1905vi, 1.5rem);
  /* Step 1: 16.8px → 28.8px */
  --step-1: clamp(1.05rem, 0.0857rem + 1.4286vi, 1.8rem);
  /* Step 2: 20.16px → 34.56px */
  --step-2: clamp(1.26rem, 0.1029rem + 1.7143vi, 2.16rem);
  /* Step 3: 24.192px → 41.472px */
  --step-3: clamp(1.512rem, 0.1234rem + 2.0571vi, 2.592rem);
  /* Step 4: 29.0304px → 49.7664px */
  --step-4: clamp(1.8144rem, 0.1481rem + 2.4686vi, 3.1104rem);
  /* Step 5: 34.8365px → 59.7197px */
  --step-5: clamp(2.1773rem, 0.1777rem + 2.9623vi, 3.7325rem);
  /* Step 6: 41.8038px → 71.6636px */
  --step-6: clamp(2.6127rem, 0.2133rem + 3.5547vi, 4.479rem);
  /* Step 7: 50.1645px → 85.9963px */
  --step-7: clamp(3.1353rem, 0.2559rem + 4.2657vi, 5.3748rem);
  /* Step 8: 60.1974px → 103.1956px */
  --step-8: clamp(3.7623rem, 0.3071rem + 5.1188vi, 6.4497rem);
  /* Step 9: 72.2369px → 123.8347px */
  --step-9: clamp(4.5148rem, 0.3686rem + 6.1426vi, 7.7397rem);

  /**
   * Font sizes
   */

  --ap-global--FontSize-title--md:     var(--step-3);  /*  1.875rem  /* Medium: 30px */
  --ap-global--FontSize-title--lg:     var(--step-7);  /*  4.063rem  /* Large: 65px */
  --ap-global--FontSize-title--xl:     var(--step-8);  /*  5rem      /* Extralarge: 80px */
  --ap-global--FontSize-title--2xl:    calc(var(--step-9) * 1.06);  /*  6.25rem   /* Extra extralarge: 100px */

  --ap-global--FontSize-paragraph--sm:  var(--step-0);  /*  1.125rem;  /* Small: 18px */
  --ap-global--FontSize-paragraph--md:  var(--step-2);  /*  1.75rem;   /* Medium: 28px */
  --ap-global--FontSize-paragraph--lg:  calc(var(--step-5) * 1.098);  /*  3.125rem;  /* Large: 50px */

  --ap-global--FontSize-label--xs:  var(--step--2);  /*  .75rem;    /* Extrasmall: 12px */
  --ap-global--FontSize-label--s:   calc(var(--step--1) * 0.91);  /*  .875rem;   /* Small: 14px */
  --ap-global--FontSize-label--sm:  calc(var(--step--1) * 1.05);  /*  1rem;      /* Medium Small: 16px */
  --ap-global--FontSize-label--md:  var(--step-0);  /*  1.125rem;  /* Medium: 18px */
  --ap-global--FontSize-label--ml:  calc(var(--step-0) * 1.09);  /*  1.25rem;   /* Medium Large: 20px */
  --ap-global--FontSize-label--lg:  calc(var(--step-3) * 0.949);  /*  1.875rem;  /* Large: 30px */
  --ap-global--FontSize-label--xl:  calc(var(--step-4) * 0.923);  /*  2.188rem;  /* Extralarge: 35px */


  /**
   * Text styles
   */

  --ap-global--Font-title--md-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-title--md) var(--ap-global--FontFamily-default);
  --ap-global--Font-title--lg-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-title--lg) var(--ap-global--FontFamily-default);
  --ap-global--Font-title--xl-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-title--xl) var(--ap-global--FontFamily-default);
  --ap-global--Font-title--2xl-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-title--2xl) var(--ap-global--FontFamily-default);

  --ap-global--Font-paragraph--sm-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-paragraph--sm) var(--ap-global--FontFamily-default);
  --ap-global--Font-paragraph--md-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-paragraph--md) var(--ap-global--FontFamily-default);
  --ap-global--Font-paragraph--md-medium: var(--ap-global--FontWeight-medium) var(--ap-global--FontSize-paragraph--md) var(--ap-global--FontFamily-default);
  --ap-global--Font-paragraph--lg-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-paragraph--lg) var(--ap-global--FontFamily-default);

  --ap-global--Font-label--xs-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-label--xs) var(--ap-global--FontFamily-default);
  --ap-global--Font-label--s-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-label--s) var(--ap-global--FontFamily-default);
  --ap-global--Font-label--sm-medium: var(--ap-global--FontWeight-medium) var(--ap-global--FontSize-label--sm) var(--ap-global--FontFamily-default);
  --ap-global--Font-label--md-medium: var(--ap-global--FontWeight-medium) var(--ap-global--FontSize-label--md) var(--ap-global--FontFamily-default);
  --ap-global--Font-label--md-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-label--md) var(--ap-global--FontFamily-default);
  --ap-global--Font-label--ml-light: var(--ap-global--FontWeight-light) var(--ap-global--FontSize-label--ml) var(--ap-global--FontFamily-default);
  --ap-global--Font-label--lg-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-label--lg) var(--ap-global--FontFamily-default);
  --ap-global--Font-label--xl-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-label--xl) var(--ap-global--FontFamily-default);

  --ap-global--Font-book--s-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-label--s) var(--ap-global--FontFamily-book);
  --ap-global--Font-book--sm-regular: var(--ap-global--FontWeight-regular) var(--ap-global--FontSize-label--sm) var(--ap-global--FontFamily-book);
}
