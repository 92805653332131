/** ***************************************************************************
 * Apus utility classes
 *************************************************************************** */

/**
 * Hidden
 */

.ap-u-hidden {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  touch-events: none;
}

.ap-u-italic {
  transition: font-variation-settings 0.1s linear;
  font-variation-settings: 'ital' 900;
  /* font-style: italic !important; */
}

.ap-u-center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
