/** ***********************************************************************************
 * Apus common styles
 *********************************************************************************** */

body {
  --ap-c-page--Font: var(--ap-global--Font-paragraph--md-regular);

  /**
   * Default color palette
   */

  --ap-c-page--AccentColor: var(--ap-global--palette-gray-dark);
  --ap-c-page--BackgroundColor: var(--ap-global--palette-white);
  --ap-c-page--Color: var(--ap-global--palette-black);
}



/**
 * Page
 * 1: reset
 */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}

html {
  /* height: 100%; */
  /* overflow-x: hidden; */
}

body {
  accent-color: var(--ap-c-page--AccentColor);
  background-color: var(--ap-c-page--BackgroundColor);
  color: var(--ap-c-page--Color);
  font: var(--ap-c-page--Font);
  height: 100%;
  overflow-x: hidden;
}



/**
 * Picture
 */

img {
  max-inline-size: 100%;
}

@supports not (max-inline-size: 0) {
  img {
    max-width: 100%;
  }
}
