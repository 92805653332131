/** ***********************************************************************************
 * Apus layout design tokens
 *********************************************************************************** */

:root {
  /**
   * Block breakpoints
   */

  --ap-global--breakpoints--block-xs: 23.25rem;  /** Extrasmall: 375px */
  --ap-global--breakpoints--block-sm: 67.5rem;   /** Small: 1080px */
  --ap-global--breakpoints--block-md: 80rem;     /** Medium: 1280px */
  --ap-global--breakpoints--block-lg: 90rem;     /** Large: 1440px */
  --ap-global--breakpoints--block-xl: 120rem;    /** Extralarge: 1920px */


  /**
   * Content grid
   */

  /** Column count */
  --ap-global--content-grid--xs--columncount: 2;
  --ap-global--content-grid--sm--columncount: 12;
  --ap-global--content-grid--columncount: var(--ap-global--content-grid--xs--columncount);

  /** Column size */
  --ap-global--content-grid--xs--columnsize: 9.5vw;     /* 152px */
  --ap-global--content-grid--sm--columnsize: 4.438rem;  /* 71px */
  --ap-global--content-grid--md--columnsize: 5.25rem;   /* 84px */
  --ap-global--content-grid--lg--columnsize: 5.938rem;  /* 95px */
  --ap-global--content-grid--xl--columnsize: 7.938rem;  /* 127px */
  --ap-global--content-grid--columnsize: var(--ap-global--content-grid--xs--columnsize);

  /*
   * Utopic space steps
   * @link https://utopia.fyi/space/calculator?c=1080,14,1.2,1920,24,1.2,7,2,1440&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12
   */

  /* Space 3xs: 4px → 6px */
  --space-3xs: clamp(0.25rem, 0.0893rem + 0.2381vi, 0.375rem);
  /* Space 2xs: 7px → 12px */
  --space-2xs: clamp(0.4375rem, 0.0357rem + 0.5952vi, 0.75rem);
  /* Space xs: 11px → 18px */
  --space-xs: clamp(0.6875rem, 0.125rem + 0.8333vi, 1.125rem);
  /* Space s: 14px → 24px */
  --space-s: clamp(0.875rem, 0.0714rem + 1.1905vi, 1.5rem);
  /* Space m: 21px → 36px */
  --space-m: clamp(1.3125rem, 0.1071rem + 1.7857vi, 2.25rem);
  /* Space l: 28px → 48px */
  --space-l: clamp(1.75rem, 0.1429rem + 2.381vi, 3rem);
  /* Space xl: 42px → 72px */
  --space-xl: clamp(2.625rem, 0.2143rem + 3.5714vi, 4.5rem);
  /* Space 2xl: 56px → 96px */
  --space-2xl: clamp(3.5rem, 0.2857rem + 4.7619vi, 6rem);
  /* Space 3xl: 84px → 144px */
  --space-3xl: clamp(5.25rem, 0.4286rem + 7.1429vi, 9rem);
  /* Space 4xl: 98px → 168px */
  --space-4xl: clamp(6.125rem, 0.5rem + 8.3333vi, 10.5rem);

  /* One-up pairs */
  /* Space 3xs-2xs: 4px → 12px */
  --space-3xs-2xs: clamp(0.25rem, -0.3929rem + 0.9524vi, 0.75rem);
  /* Space 2xs-xs: 7px → 18px */
  --space-2xs-xs: clamp(0.4375rem, -0.4464rem + 1.3095vi, 1.125rem);
  /* Space xs-s: 11px → 24px */
  --space-xs-s: clamp(0.6875rem, -0.3571rem + 1.5476vi, 1.5rem);
  /* Space s-m: 14px → 36px */
  --space-s-m: clamp(0.875rem, -0.8929rem + 2.619vi, 2.25rem);
  /* Space m-l: 21px → 48px */
  --space-m-l: clamp(1.3125rem, -0.8571rem + 3.2143vi, 3rem);
  /* Space l-xl: 28px → 72px */
  --space-l-xl: clamp(1.75rem, -1.7857rem + 5.2381vi, 4.5rem);
  /* Space xl-2xl: 42px → 96px */
  --space-xl-2xl: clamp(2.625rem, -1.7143rem + 6.4286vi, 6rem);
  /* Space 2xl-3xl: 56px → 144px */
  --space-2xl-3xl: clamp(3.5rem, -3.5714rem + 10.4762vi, 9rem);

  /* Custom pairs */
  /* Space s-l: 14px → 48px */
  --space-s-l: clamp(0.875rem, -1.8571rem + 4.0476vi, 3rem);


  /** Gutter width */
  --ap-global--content-grid--xs--guttersize: var(--space-2xs);  /* .625vw;    /* 10px */
  --ap-global--content-grid--sm--guttersize: var(--space-xs);   /* .938rem;   /* 15px */
  --ap-global--content-grid--md--guttersize: var(--space-s);    /* 1.125rem;  /* 18px */
  --ap-global--content-grid--lg--guttersize: calc(var(--space-s) * 1.11);    /* 1.25rem;   /* 20px */
  --ap-global--content-grid--xl--guttersize: var(--space-m);    /* 1.625rem;  /* 26px */
  --ap-global--content-grid--guttersize: var(--ap-global--content-grid--xs--guttersize);

  --ap-global--content-grid--GridTemplateColumns: repeat(var(--ap-global--content-grid--columncount), minmax(var(--ap-global--content-grid--columnsize), 1fr));


  /**
   * Page grid
   */

  --ap-global--page-grid--contentsize: calc(
    var(--ap-global--content-grid--columncount) * var(--ap-global--content-grid--columnsize)
    + (var(--ap-global--content-grid--columncount) - 1) * var(--ap-global--content-grid--guttersize)
  );

  --ap-global--page--paddingsize: 1.875rem; /* 30px */
  --ap-global--page--sm--paddingsize: calc(var(--space-l) * 1.07);

  --ap-global--page-grid--sm--GridTemplateColumns: repeat(var(--ap-global--content-grid--columncount), minmax(71px, 127px));
}

/**
 * Small
 */

 @media all and (min-width: 67.5rem) {
  :root {
    --ap-global--content-grid--columncount: var(--ap-global--content-grid--sm--columncount);
    --ap-global--content-grid--columnsize: var(--ap-global--content-grid--sm--columnsize);
    --ap-global--content-grid--guttersize: var(--ap-global--content-grid--sm--guttersize);
    --ap-global--page--paddingsize: var(--ap-global--page--sm--paddingsize);
  }
}

/**
 * Medium
 */

 @media all and (min-width: 80rem) {
  :root {
    --ap-global--content-grid--columnsize: var(--ap-global--content-grid--md--columnsize);
    --ap-global--content-grid--guttersize: var(--ap-global--content-grid--md--guttersize);
  }
}

/**
 * Large
 */

 @media all and (min-width: 90rem) {
  :root {
    --ap-global--content-grid--columnsize: var(--ap-global--content-grid--lg--columnsize);
    --ap-global--content-grid--guttersize: var(--ap-global--content-grid--lg--guttersize);
  }
}

/**
 * Extralarge
 */

 @media all and (min-width: 120rem) {
  :root {
    --ap-global--content-grid--columnsize: var(--ap-global--content-grid--xl--columnsize);
    --ap-global--content-grid--guttersize: var(--ap-global--content-grid--xl--guttersize);
  }
}
